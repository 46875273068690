import React from "react";

import "./FooterOverlay.css";

const FooterOverlay = () => (
   <div className="app__footerOverlay">
      <div className="app__footerOverlay-prussian" />
      <div className="app__footerOverlay-img app__bg" />
   </div>
);

export default FooterOverlay;
